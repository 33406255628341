import { lazy } from "react";

const HomePage = lazy(() => import("../pages/Home"));

const routers = [
  {
    path: "/",
    exact: true,
    public: true,
    element: <HomePage />,
  },
  // Additional routers...
];

export default routers;
